jQuery(function($){
    const $_ = {
        init(){
            this.initCache();
            this.initDonut();
            this.initEvents();

            $_._collectCashFlowFormValues();
            $_._calcCashflow();
        },

        initCache() {
            this.donutSelector = '.js-cashflow-donut';
            this.chart = null;

            this.$formCashFlow = $('.js-cashflow-calc-form');
            
            this.$calcTotal = $('.js-calc-total');

            this.$monthlyPayment = $('.js-monthly-payment');
            this.$propertyTax = $('.js-property-tax');
            this.$basicInsuranceCost = $('.js-basic-insurance-cost');
            if($('.js-maintenance-cost').length){
                this.$maintenanceCost = $('.js-maintenance-cost');
            }else{
                this.$maintenanceCost = null;
            }
            this.$rentalIncome = $('.js-rental-income');
            this.$homePriceInput = $('.js-home-price');
            this.$downPaymentPercentInput = $('.js-down-payment-percent');
            this.$downPaymentInput = $('.js-down-payment');

            this.$monthlyPaymentTotalInput = $('.js-monthly-payment-total');

            this.$breakEvenDownPayment = $('.js-break-even-down-payment');

            this.formCashFlowValues = {};
        },

        initEvents() {
            $_.$formCashFlow.on('change', () => {
                $_._collectCashFlowFormValues();
                $_._calcCashflow();
            });
        },

        _calcCashflow() {
            const { property_tax, maintenance_cost, rental_income, monthly_payment_total, home_price, down_payment, basic_insurance_cost  } = $_.formCashFlowValues;
        
            // Convert values to numbers and handle NaN cases
            const tax = Number(property_tax) || 0;
            const maintenance = Number(maintenance_cost) || 0;
            const income = Number(rental_income) || 0;
            const payment_total = Number(monthly_payment_total) || 0;
            const price = Number(home_price) || 0;
            const payment = Number(down_payment) || 0;
            const insurance_cost = Number(basic_insurance_cost) || 0;
        
            // Ensure calculation doesn't result in NaN
            const principalAndInterest = income - (tax + maintenance + payment_total + insurance_cost);
            const newMortgagePayment = income - (tax + maintenance + insurance_cost);
            let calcBreakEvenDownPayment = (((price - ((newMortgagePayment/payment_total) * (price - payment)))/price)*100);
            if(calcBreakEvenDownPayment < 0){
                calcBreakEvenDownPayment = 0;
            }else{
                calcBreakEvenDownPayment = Math.ceil(calcBreakEvenDownPayment);
            }        
    
            // Update UI safely
            $_.$monthlyPayment.html(_formatCurrencyCa(principalAndInterest));
            $_.$breakEvenDownPayment.html(calcBreakEvenDownPayment+'%');
            $_._updateDonut([Math.max(principalAndInterest,0), 202, 75, 513]);
        },

        _updateDonut(series) {
            $_.chart.update({series: series});
        },

        _collectCashFlowFormValues() {
            $_.$formCashFlow.serializeArray().map(item => {
                $_.formCashFlowValues[item.name] = _getPureNumber(item.value);
            });

        },

        initDonut() {
            const
                data = {
                    series: [16877, 202, 75, 513],
                },
                options = {
                    donut: true,
                    donutWidth: 40,
                    donutSolid: true,
                    startAngle: 0,
                    showLabel: false,
                },
                responsiveOptions = [
                    ['screen and (max-width: 1000px)', {
                        donutWidth: 35,
                    }],
                    ['screen and (max-width: 700px)', {
                        donutWidth: 28,
                    }]
                ];

            $_.chart = new Chartist.Pie($_.donutSelector, data, options, responsiveOptions);
        }
    };

    $(document).ready(() => {
        if ($('.js-cashflow-donut').length) $_.init();
    });
});
