$(document).ready(function () {
    $("#chartForm").submit(function (event) {
        event.preventDefault(); // Prevent page reload
        $("#price-evaluation").addClass('_loading-data');
        
        let formData = $(this).serialize(); // Serialize form data
        $.ajax({
            type: "POST",
            url: "/chart-data", // Change to your actual endpoint
            data: formData,
            dataType: "json",
            success: function (response) {
                $("#price-evaluation").removeClass('_loading-data');
                $("#cityLabelPricelabel").text(response.cityLabelPrice);
                $("#subdivisionLabelPricelabel").text(response.subdivisionLabelPrice);
                // $("#averagesoldpricelabel").text(response.minSoldPrice +' - '+response.maxSoldPrice);
                $('.map-section__filter-popup').toggleClass('_active');
                $('.js-toggle-active').removeClass('_active');
                updateChart(response);
            },
            error: function (xhr) {
                $("#price-evaluation").removeClass('_loading-data');
            }
        });
    });

    $("[name='filter-bed']").on("change", function() {
        $("#chartForm").submit();
    });

    $('input[name="propertyTypes[]"]').on("change", function () {
        if ($(this).is(":checked")) {
          console.log($(this).val() + " checked");
        } else {
          console.log($(this).val() + " unchecked");
        }
        $("#chartForm").submit();
    });

    /* $('select[name="minPrice"], select[name="maxPrice"]').on("change", function () {
        // console.log($(this).attr("name") + " changed to " + $(this).val());
    
        let minPrice = $('select[name="minPrice"]').val();
        let maxPrice = $('select[name="maxPrice"]').val();
    
        // if (minPrice && maxPrice) {
            $("#chartForm").submit();
        // }
    }); */

    $('select[name="squareFeet"]').on("change", function () {    
        $("#chartForm").submit();
    });

    $('select[name="lotSize"]').on("change", function () {    
        $("#chartForm").submit();
    });

    $('select[name="yearBuilt"]').on("change", function () {    
        $("#chartForm").submit();
    });
 
    $('input[name="priceType"]').on("change", function () {
        $("#chartForm").submit();
    });

    $('.open-filter-popup').on('click', () => {
        $('.map-section__filter-popup').toggleClass('_active');
    });

    $('.map-section__filter-popup > .cross-btn').on('click', () => {
        $('.map-section__filter-popup').toggleClass('_active');
    });
    

});